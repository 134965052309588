<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>會員管理中心</b-breadcrumb-item>
            <b-breadcrumb-item active>會員等級管理</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <h4 class="mb-2 mb-xl-0 mr-2 font-weight-bold">會員等級管理</h4>
      <div class="row my-3">
        <div class="col-12 col-xl-4"></div>
        <div
          class="
            col-12 col-xl-8
            d-flex
            align-items-start align-items-xl-center
            justify-content-end
          "
        >
          <b-button
            v-if="$permissions.has($permissions.consts.CUSTOMER_LEVEL_CREATE)"
            variant="primary"
            class="flex-shrink-0 mb-2 mb-xl-0"
            :to="{ name: 'CustomerLevelsCreate' }"
          >
            新增會員等級
          </b-button>
          <b-form-select
            class="ml-2"
            v-model="query.is_enabled"
            :options="isEnableOptions"
            @change="handlePageReset"
          ></b-form-select>
          <b-form-select
            class="ml-2"
            v-model="query.is_processing"
            :options="isProcessingOptions"
            @change="handlePageReset"
          ></b-form-select>
          <b-input-group class="mb-2 mb-xl-0 col-xl-4">
            <b-form-input
              v-model="query.keyword"
              @keyup.enter="handlePageReset"
              placeholder="搜尋名字、描述"
            ></b-form-input>
            <b-input-group-append>
              <b-button @click="handlePageReset"
                ><i class="fa fa-search"></i
              ></b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-table
            striped
            hover
            responsive
            :items="levels"
            :fields="fields"
            :busy="isLoading"
          >
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle"></b-spinner>
              </div>
            </template>
            <template #cell(name)="data">
              <router-link
                :to="{
                  name: 'CustomerLevelsEdit',
                  params: { id: data.item.id },
                }"
                >{{ hiddenString(data.item.name, 20) }}</router-link
              >
            </template>
            <template #cell(start_to_end)="data">
              {{ convertToStartToEnd(data.item) }}
            </template>
            <template #cell(is_enabled)="data">
              <b-badge
                :variant="
                  !!data.item.is_enabled === true ? 'success' : 'danger'
                "
                class="mr-1"
                >{{
                  !!data.item.is_enabled === true ? "啟用" : "停用"
                }}</b-badge
              >
            </template>
            <template #cell(actions)="data">
              <b-button
                v-if="checkPermission([consts.CUSTOMER_LEVEL_LIST])"
                class="ml-2"
                variant="inverse-info"
                :to="{
                  name: 'CustomerLevelsCustomerList',
                  params: { id: data.item.id },
                }"
                ><span class="mdi mdi-account-multiple"></span>
                會員列表</b-button
              >

              <b-button
                v-if="
                  checkPermissionAny([
                    consts.CUSTOMER_LEVEL_AUTO_CHANGE_VIEW,
                    consts.CUSTOMER_LEVEL_AUTO_CHANGE_EDIT,
                  ])
                "
                class="ml-2"
                variant="inverse-info"
                :to="{
                  name: 'CustomerLevelsAutoChangeForm',
                  params: { id: data.item.id },
                }"
                ><span class="mdi mdi-settings"></span> 規則設定</b-button
              >

              <b-button
                v-if="checkPermission([consts.CUSTOMER_LEVEL_EDIT])"
                class="ml-2"
                variant="inverse-primary"
                :to="{
                  name: 'CustomerLevelsView',
                  params: { id: data.item.id },
                }"
                ><span class="mdi mdi-eye"></span> 查看</b-button
              >

              <b-button
                v-if="checkPermission([consts.CUSTOMER_LEVEL_EDIT])"
                class="ml-2"
                variant="inverse-warning"
                :to="{
                  name: 'CustomerLevelsEdit',
                  params: { id: data.item.id },
                }"
                ><span class="mdi mdi-lead-pencil"></span> 編輯</b-button
              >

              <b-button
                v-if="checkPermission([consts.CUSTOMER_LEVEL_DELETE])"
                class="ml-2"
                variant="inverse-danger"
                @click="handleDelete(data.item)"
                ><span class="mdi mdi-delete"></span> 刪除</b-button
              >
            </template>
          </b-table>
        </div>
      </div>

      <CustomPagination
        :currentPage="query.page"
        :totalRows="totalRows"
        :perPage="query.per_page"
        @page-change="handlePageChange"
        @per-page-change="handlePerPageChange"
      />
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import customerLevelApi from "@/apis/customer-levels";
import * as consts from "@/consts";
import PermissionChecker from "@/utils/PermissionChecker";
import moment from "moment";
import "bootstrap-vue/dist/bootstrap-vue.css";
import { paginationMixin } from "@/mixins/pagination";
import CustomPagination from "@/components/Page/Dashboard/CustomPagination.vue";
import { updateQueryFromRoute, updateUrl } from "@/utils/updateUrl";

export default {
  components: { CustomPagination },
  mixins: [paginationMixin],
  data() {
    return {
      consts,
      isLoading: false,
      totalRows: 0,
      isFetchCustomerList: false, // old
      customers: [], // decp
      levels: [],
      isEnableOptions: [
        { text: "全部", value: null },
        { text: "已啟用", value: true },
        { text: "已停用", value: false },
      ],
      isProcessingOptions: [
        { text: "全部", value: null },
        { text: "生效中", value: true },
        { text: "已失效", value: false },
      ],
      fields: [
        { key: "order", label: "等級排序" },
        { key: "name", label: "名稱" },
        // { key: "code", label: "代碼" },
        { key: "customers_count", label: "人數" },
        { key: "description", label: "說明" },
        { key: "start_to_end", label: "有效期間" },
        { key: "is_enabled", label: "是否啟用" },
        { key: "actions", label: "管理" },
      ],
      initialized: false,
      query: {
        page: 1,
        per_page: 10,
        is_enabled: true,
        is_processing: true,
        keyword: "",
      },
    };
  },

  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    queryWatcher() {
      return `${this.query.page}-${this.query.per_page}`;
    },
  },

  watch: {
    queryWatcher: {
      handler() {
        if (!this.initialized) return;
        updateUrl(this.query, this.$store, this.$router);
      },
    },
    $route(to) {
      if (!this.initialized) return;
      Object.keys(to.query).forEach((key) => {
        this.query[key] = to.query[key] || null;
      });
      this.getCustomerLevelsList();
    },
  },

  created() {
    this.query = updateQueryFromRoute(this.$route, this.$store, this.query);
    this.init();
  },

  methods: {
    async init() {
      await this.getCustomerLevelsList();
      this.initialized = true;
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
    checkPermissionAny(permissions) {
      const checker = new PermissionChecker();
      return checker.checkAny(permissions);
    },
    convertToStartToEnd(level) {
      let startAt = level.start_at
        ? moment(level.start_at).format("YYYY-MM-DD")
        : "";
      let endAt = level.end_at ? moment(level.end_at).format("YYYY-MM-DD") : "";

      return startAt + " - " + endAt;
    },
    async getCustomerLevelsList() {
      try {
        this.isLoading = true;

        const { data } = await customerLevelApi.list({
          ...this.query,
        });

        this.levels = data.data;
        this.totalRows = data.meta.total;
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    hiddenString(value, length) {
      value = value || "";
      const splittedValue = [...value];
      if (splittedValue.length <= length) {
        return value;
      }
      return splittedValue.slice(0, length).join("") + "...";
    },
    async handleDelete(level) {
      let confirm = await this.$swal.fire({
        type: "warning",
        title: "刪除會員等級",
        text: `您確認要刪除 ${level.name} 這個會員等級嗎？所有此等級會員將會降回預設等級`,
        confirmButtonText: "確認",
        cancelButtonText: "取消",
        showCancelButton: true,
      });

      if (!confirm.value) return;

      try {
        await customerLevelApi.delete(level.id);
        this.$swal("刪除成功", "", "success");
        await this.getCustomerLevelsList();
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "", "error");
      }
    },
  },
};
</script>

<style></style>
